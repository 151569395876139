/** @jsxImportSource @emotion/react */

// Dependencies
import { useTheme } from "@emotion/react";
import { useContext, useEffect, useState } from "react";

// Components
import { Flex } from "../Flex";
import GraphCard from "./components/GraphCard";
import GraphScoreCardHeader from "./components/GraphScoreCardHeader";
import ScoreCard from "./components/ScoreCard";

// Hooks
import useFeedbackPopUp from "hooks/useFeedbackPopUp";
import useGeoExploratory from "hooks/useGeoExploratory";
import { useTimeSeriesData } from "hooks/useTimeSeriesData";

// Utilities
import { MapContext, ResponsiveContext } from "lib/context";
import { formatterMap } from "lib/options/formatterMap";
import { timeSeriesDatapointConfig } from "lib/options/timeseriesDatapointConfig";

// Types
import { GraphContext } from "components/GraphContext";
import { getGeoCodeFromFeature } from "lib/helpers/mapHelpers";
import { Geo } from "types/MapContext";
import { CombinedDatapoints, Datapoints } from "types/cube";

export const TimeSeriesExploratory = ({
  info,
  exploratory,
  geo,
  handleExploratoryChange,
  onShareClick,
}: any) => {
  const theme = useTheme();

  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const { setShowShareModal } = useContext(MapContext);

  const { groupedExploratoriesWithScoreData } = useGeoExploratory(geo);
  const { showFeedbackPopup } = useFeedbackPopUp();

  const [datapoint, setDatapoint] = useState<CombinedDatapoints>(exploratory);
  const [showYearly, setShowYearly] = useState(true);
  const [showSecondaryLine, setShowSecondaryLine] = useState(false);
  const [subPrimaryDatapoint, setSubPrimaryDatapoint] =
    useState<CombinedDatapoints>(datapoint);

  useEffect(() => {
    setDatapoint(exploratory);
  }, [exploratory]);

  useEffect(() => {
    if ((datapoint as Datapoints) == "salary_to_afford_house") {
      setSubPrimaryDatapoint(Datapoints.MEDIAN_HOUSEHOLD_INCOME);
    } else if ((datapoint as Datapoints) == "home_value"){
      setSubPrimaryDatapoint(Datapoints.FAIR_HOME_VALUE);
    } else {
      // @ts-ignore
      setSubPrimaryDatapoint(undefined);
    }
  }, [datapoint]);

  const datapointConfig = timeSeriesDatapointConfig[datapoint];

  const primaryDatapoint: CombinedDatapoints = datapoint;

  const { primaryFormatter, secondaryFormatter } =
    formatterMap[primaryDatapoint];
  const stateCode =
    geo === Geo.COUNTY ? info.state : info.state_code || info.STATE;
  const geoCode = getGeoCodeFromFeature(info, geo);
  const {
    plotData,
    subPlotData,
    tooltipValues,
    subTooltipValues,
    isTimeSeriesLoading,
    activeMonth,
    latestMonth,
  } = useTimeSeriesData({
    geo,
    primaryDatapoint,
    subPrimaryDatapoint,
    geoCode: geoCode,
    stateCode: stateCode,
    datapoint,
    showYearly,
    showSecondaryLine,
    ...datapointConfig,
  });
  // @ts-ignore
  const { plotData: monthlyPlotData } = useTimeSeriesData({
    geo,
    primaryDatapoint,
    geoCode: geoCode,
    stateCode: stateCode,
    datapoint,
    showYearly: false,
    showSecondaryLine,
    ...datapointConfig,
  });
  // @ts-ignore
  const { plotData: yearlyPlotData } = useTimeSeriesData({
    geo,
    primaryDatapoint,
    geoCode: geoCode,
    stateCode: stateCode,
    datapoint,
    showYearly: true,
    showSecondaryLine,
    ...datapointConfig,
  });
  function handleDatapointChange(
    value: CombinedDatapoints,
    updateExploratory: boolean,
  ) {
    setDatapoint(value);
    if (value == "salary_to_afford_house") {
      setSubPrimaryDatapoint(Datapoints.TYPICAL_HOUSE_PAYMENT);
    }
    if (value == "home_value") {
      setSubPrimaryDatapoint(Datapoints.FAIR_HOME_VALUE);
    }
    if (updateExploratory) {
      handleExploratoryChange(value);
    }

    return true;
  }

  return (
    <div css={{ marginTop: isTabletOrMobile ? 40 : 0 }}>
      <GraphScoreCardHeader
        geo={geo}
        info={info}
        plotData={plotData}
        showYearly={showYearly}
        activeMonth={activeMonth}
        latestMonth={latestMonth}
        exploratory={datapoint}
        onShareClick={onShareClick}
        setShowYearly={setShowYearly}
        exploratoryConfig={datapointConfig}
        setShowShareModal={setShowShareModal}
      />

      <Flex
        direction={isTabletOrMobile ? "column-reverse" : "row"}
        gap={theme.gap[3]}
        css={{ margin: "0 15px" }}
      >
        {isTabletOrMobile && (
          <Flex css={{}}>
            <p css={{ fontSize: theme.fontSizes.small, margin: "0 10px" }}>
              <span css={{ fontWeight: theme.fontWeights.bold }}>Note:</span>{" "}
              this score is a probabilistic projection of where home prices
              could head in the next 12 months, but is not a guarantee and could
              be subject to error.
            </p>
          </Flex>
        )}
        <ScoreCard
          geoCode={geoCode}
          geo={geo}
          GEOID={geo === Geo.COUNTY ? `${stateCode}${geoCode}` : geoCode}
          stateCode={stateCode}
          name={geo === Geo.STATE ? info.FULLNAME : info.BASENAME}
        />
        {isTabletOrMobile && (
          <GraphContext
            geo={geo}
            activeMonth={activeMonth}
            latestMonth={latestMonth}
            plotData={plotData}
            exploratory={exploratory}
            context={info}
            fullName={""}
          />
        )}
        <GraphCard
          geo={geo}
          info={info}
          plotData={plotData}
          monthlyPlotData={monthlyPlotData}
          yearlyPlotData={yearlyPlotData}
          subPlotData={subPlotData}
          datapoint={datapoint}
          primaryFormatter={primaryFormatter}
          showFeedbackPopup={showFeedbackPopup}
          secondaryFormatter={secondaryFormatter}
          groupedExploratoriesWithScoreData={groupedExploratoriesWithScoreData}
          handleExploratoryChange={handleDatapointChange}
          loading={isTimeSeriesLoading}
          setShowSecondaryLine={setShowSecondaryLine}
          tooltipValues={tooltipValues}
          subTooltipValues={subTooltipValues}
          showYearly={showYearly}
          showSecondaryLine={showSecondaryLine}
          exploratoryConfig={datapointConfig}
        />
      </Flex>
    </div>
  );
};
